<template>
  <div class="mainwrap coupenlist" v-loading="loading">
    <div class="title">優惠券管理</div>
    <div class="searchform">
      <el-form
        label-width="90px"
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="名稱：">
          <el-input
            v-model="searchForm.Name"
            placeholder="請輸入名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="活動編號：">
          <el-input
            v-model="searchForm.No"
            placeholder="請輸入活動編號"
          ></el-input>
        </el-form-item>
        <el-form-item label="派券類型：">
          <el-select
            v-model="searchForm.ReceiveType"
            placeholder="請選擇派券類型"
          >
            <el-option
              v-for="item in DispatchVolume"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抵用券類型：" label-width="100px">
          <el-select v-model="searchForm.Type" placeholder="請選擇抵用券類型">
            <el-option
              v-for="item in offsetVolumeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用期限：">
          <el-date-picker
            :editable="false"
            v-model="searchForm.Date"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="開始時間"
            end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="兌換地點：">
          <el-input v-model="searchForm.UsePlace" placeholder="請輸入兌換地點"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <el-button type="primary" @click="getRefreshActivity()"
            >更 新</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="名稱"> </el-table-column>
        <el-table-column prop="no" label="活動編號"></el-table-column>
        <el-table-column prop="dateTime" label="使用期限"></el-table-column>
        <el-table-column prop="receiveDateTime" label="有效期限">
        </el-table-column>
        <el-table-column prop="isEnable" label="是否啓用"></el-table-column>
        <el-table-column label="APP排序">
          <template slot-scope="scope">
            <span>{{ scope.row.sort != null ? scope.row.sort : "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="publishNum" label="發行張數"></el-table-column>
        <el-table-column prop="receiveNum" label="領用張數"></el-table-column>
        <el-table-column prop="writeoffNum" label="核銷張數"></el-table-column>
        <el-table-column prop="waterLine" label="警示水位"></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button v-if="permissionType === 2" @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <el-button @click="Details(scope.row)" type="info">
              明細
            </el-button>
            <!-- <div class="delbtn">
              <el-popconfirm
              confirm-button-text='確定'
              cancel-button-text='取消'
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="確定刪除？"
              @confirm="delCoupon(scope.row.id)"
            >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: "couponlist",
  data() {
    return {
      loading: false,
      searchForm: {
        Name: "",
        Date: "",
        No: "",
        ReceiveType: "",
        Type: "",
      },
      tableData: [],
      offsetVolumeList: [],
      DispatchVolume: [
        {
          value: "未設定",
          id: -1,
        },
        {
          value: "會員領取",
          id: -0,
        },
        {
          value: "系統派發",
          id: 1,
        },
      ],
      isSinglePage: false,
      pageSize: 10,
      currentPage: 1,
      tableDataTotal: 0,
      permissionType: -1
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cms/couponedit") {
        this.getCouponList();
      }
    },
  },
  created() {
    this.getCouponList();
    this.getTypeSelectList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
  methods: {
    getRefreshActivity() {
      this.loading = true;
      api.getRefreshActivity().then((res) => {
        if (res.data) {
          this.getCouponList();
        }
        this.loading = false;
      });
    },
    getTypeSelectList() {
      this.loading = true;
      api.getTypeSelectList().then((res) => {
        if (res.systemCode === 200) {
          this.offsetVolumeList = res.data;
        }
        this.loading = false;
      });
    },
    onSearch() {
      this.currentPage = 1;
      this.getCouponList();
    },
    reSet() {
      this.searchForm = {
        Name: "",
        Date: "",
        No: "",
      };
      this.currentPage = 1;
      this.getCouponList();
    },
    handleEdit(item) {
      this.$router.push({
        path: "/cms/couponedit",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    Details(item) {
      this.$router.push({
        path: "/cms/details",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    delCoupon(id) {
      console.log(id);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCouponList();
    },
    getCouponList() {
      this.loading = true;
      api
        .getCouponList({
          Name: this.searchForm.Name,
          No: this.searchForm.No,
          StartTime: this.searchForm.Date ? this.searchForm.Date[0] : "",
          EndTime: this.searchForm.Date ? this.searchForm.Date[1] : "",
          UsePlace: this.searchForm.UsePlace,
          ReceiveType: this.searchForm.ReceiveType,
          Type: this.searchForm.Type,
          SkipCount: (this.currentPage - 1) * this.pageSize,
          MaxResultCount: this.pageSize,
        })
        .then((res) => {
          if (res.systemCode === 200) {
            this.tableData = res.data.items;
            this.tableDataTotal = res.data.totalCount;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupenlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .delbtn {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
