<template>
  <div class="mainwrap coupenlist" v-loading="loading">
    <div class="title">會員票夾管理</div>
    <div class="searchform">
      <el-form
        label-width="90px"
        :inline="true"
        :rules="rules"
        :model="searchForm"
        ref="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="手機：" prop="mobile">
          <el-input
            v-model="searchForm.mobile"
            placeholder="請輸入手機"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名：">
          <el-input
            v-model="searchForm.Name"
            placeholder="請輸入姓名"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="優惠券名稱"> </el-table-column>
        <el-table-column prop="no" label="優惠券券號"></el-table-column>
        <el-table-column
          prop="dateTime"
          label="使用期限"
        ></el-table-column>
        <el-table-column prop="receiveTime" label="領取時間"></el-table-column>
        <el-table-column prop="usedTime" label="使用時間"></el-table-column>
        <el-table-column prop="transferTime" label="轉贈時間"></el-table-column>
        <el-table-column
          prop="recipientMobile"
          label="受贈者電話"
        ></el-table-column>
        <el-table-column
          prop="transferorMobile"
          label="轉贈者電話"
        ></el-table-column>
        <!-- <el-table-column
          prop="paymentCounter"
          label="使用廳別"
        ></el-table-column>
        <el-table-column
          prop="paymentAmount"
          label="消費金額"
        ></el-table-column> -->
        <el-table-column label="消費明細">
          <!-- <template slot-scope="scope">
            <el-button
              v-if="scope.row.detail"
              type="primary"
              @click="showDis(scope.row.detail)"
              >查看</el-button
            >
          </template> -->
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.hasRecord"
              @click="handleDest(scope.row)"
              type="primary"
              plain
            >
              消費明細
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="详情" :visible.sync="dialogTableVisible">
      <el-table v-if="dialogTableVisible" :data="openList">
        <el-table-column
          v-for="(val, i, index) in openList[0]"
          :key="index"
          :prop="i"
        >
          <template slot="header">
            {{ i }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
export default {
  name: "memberWallet",
  data() {
    return {
      dialogTableVisible: false,
      loading: false,
      openList: [],
      searchForm: {
        mobile: "",
        sorting: "",
        maxResultCount: 10,
      },
      tableData: [],
      isSinglePage: false,
      pageSize: 10,
      currentPage: 1,
      tableDataTotal: 0,
      rules: {
        mobile: [
          { required: true, message: "請輸入會員手機號碼", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    showDis(e) {
      this.openList = [];
      this.dialogTableVisible = true;
      this.openList = JSON.parse(e);
    },
    onSearch() {
      this.currentPage = 1;
      this.getCouponList();
    },
    reSet() {
      this.searchForm = {
        mobile: "",
        sorting: "",
        maxResultCount: 10,
      };
      this.currentPage = 1;
      this.getCouponList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCouponList();
    },
    // 查看明細
    handleDest(item) {
      console.log(this.brandId);
      this.$router.push({
        path: "/cms/memberWalletLog",
        query: {
          id: item.id,
        },
      });
    },
    getCouponList() {
      this.$refs["searchForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          api
            .couponWallet({
              Mobile: this.searchForm.mobile,
              Sorting: this.searchForm.sorting,
              SkipCount: (this.currentPage - 1) * this.pageSize,
              MaxResultCount: this.pageSize,
            })
            .then((res) => {
              if (res.systemCode === 200) {
                this.tableData = res.data.items;
                this.tableDataTotal = res.data.totalCount;
              }
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupenlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .delbtn {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
